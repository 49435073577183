import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../vendor/splitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export default class ApproachHeadingAnimation {
	constructor(sceneObject) {
		// Animate text
		const split = new SplitText(
			sceneObject.el.querySelector("[data-experience-heading"),
			{ type: "words", linesClass: "lineChild" }
		);

		// Outer wrap each line with a div
		split.words.forEach((line) => {
			const div = document.createElement("span");
			div.classList.add("overflow-hidden", "py-1", "-mb-1");
			line.parentNode.insertBefore(div, line);
			div.appendChild(line);
		});

		const words = split.words;

		gsap.set(words, { y: 32, autoAlpha: 0 });

		ScrollTrigger.create({
			trigger: sceneObject.el,
			start: "0% 50%",
			end: "100% 0",
			onEnter: () => {
				gsap.to(words, {
					y: 0,
					autoAlpha: 1,
					duration: .35,
					ease: "power2.out",
					stagger: .075,
				});
			},
			onLeaveBack: () => {
				gsap.to(words, {
					y: 32,
					autoAlpha: 0,
					duration: .35,
					ease: "power2.in",
					stagger: .075,
				});
			},
		});
	}
}
