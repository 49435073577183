import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import Stats from "stats.js";
import GUI from "lil-gui";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { throttle } from "../../utilities/_throttleDebounce";
import Cookies from "js-cookie";

import PeopleExperienceAnimation from "./PeopleExperienceAnimation";
import ApproachExperienceAnimation from "./ApproachExperienceAnimation";
import ApproachHeadingAnimation from "./ApproachHeadingAnimation";
import MeshPhysicalMaterialAbs from "./MeshPhysicalMaterialAbs";
import presets from "./presets";

gsap.registerPlugin(ScrollTrigger);

const experience = {
  container: null,
  containerSize: {
    width: null,
    height: null,
  },
  containerSync: null,
  cursor: null,
  savedPreset: null,
  clockDelta: 0,
  clockElapsedTime: 0,
  clockLastTime: Date.now(),
  composer: null,
  experiences: [],
  gui: null,
  matGlass1: null, // hero
  matGlass2: null, // footer
  matGlass3: null, // approach1
  matGlass4: null, // approach2
  matGlass5: null, // approach3
  matGlass6: null, // approach4
  matRadial1: null, // hero
  matRadial2: null, // hero
  renderer: null,
  scenes: {},
  stats: null,

  async init(experiences) {
    const self = this

    // Setup
    this.experiences = experiences;
    this.container = document.querySelector("#c");

    // If there's a saved preset in Craft, store the values for use later on
    //
    if (this.container.hasAttribute('data-preset')) {
      this.savedPreset = JSON.parse(this.container.getAttribute('data-preset'))
    }

    if (!this.container || !this.experiences.length) return;

    this.containerSync = this.container.dataset.sync || "transform";
    this.containerSize.width = this.container.offsetWidth;
    this.containerSize.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });

    this.renderer.setSize(this.containerSize.width, this.containerSize.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.container.appendChild(this.renderer.domElement);
    window.addEventListener("resize", this.resize.bind(this));

    // GUI
    this.gui = new GUI();
    let preset = {};
    window.addEventListener("keydown", (event) => {
      if (event.key == "h") this.gui.show(this.gui._hidden);
    });
    this.gui.hide();

    // Lil-gui feature for saving preset to craft form
    //
    const savePreset = () => {
      preset = this.gui.save();

      return JSON.stringify(preset)
    }
    const saveBtn = document.querySelector('.save')
    if (saveBtn !== null) {
      saveBtn.addEventListener('click', function () {
        saveBtn.setAttribute('data-preset', savePreset())
      })
    }

    // Stats
    // this.stats = new Stats();
    // this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(this.stats.dom);

    // Cursor
    this.cursor = {}
    this.cursor.x = 0
    this.cursor.y = 0

    window.addEventListener('mousemove', function(e) {
      self.cursor.x = e.clientX / self.containerSize.width - 0.5
      self.cursor.y = e.clientY / self.containerSize.height - 0.5
    })

    // Create materials
    this.createMaterials();

    // Create scene objects
    this.experiences.forEach((experience) => {
      const key = experience.dataset.experience; // scene name/key
      const setup = experience.dataset.experienceSetup;
      const el = experience;

      if (!setup) return;

      // Init scene object
      this.scenes[key] = {
        animate: () => {},
        camera: null,
        composer: null,
        el,
        grpTubes: new THREE.Group(),
        scene: null,
        setup: this[setup],
        scissor: true,
        triggered: false,
        useGlobalEnvMap: true,
        useGlobalLights: true,
      };
    });

    // Load models
    await this.loadModels();

    // Setup scenes
    for (const key in this.scenes) {
      const { setup } = this.scenes[key];
      setup.call(this, this.scenes[key]);
    }

    // Manually setup environment scene as it has no DOM element
    this.setupEnvironmentScene();

    // Run
    this.addGlobalLights();
    this.resize();
    this.render();
  },

  /**
   * Map dependencies for each experience
   * @returns {Map} - Map of dependencies
   */
  mapDependencies() {
    const deps = new Map();
    this.experiences.forEach((experience) => {
      const key = experience.dataset.experience; // scene name/key
      const exImport = experience.dataset.experienceImport;
      const exMeshes = experience.dataset.experienceMeshes;

      // Check if import exists as map key
      if (!deps.has(exImport)) {
        deps.set(exImport, []);
      }

      // Push { key: exMeshes } to map value: exImport
      deps.get(exImport).push({ key, exMeshes });
    });

    return deps;
  },

  /**
   * Load models and meshes using map of dependencies
   */
  async loadModels() {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/dist/draco/");
    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    const map = this.mapDependencies();

    // Loop over map keys
    for (const [key, value] of map) {
      const gltf = await gltfLoader.loadAsync(`/dist/models/${key}.gltf`);

      // Loop over map values
      for (const obj of value) {
        // Split exMeshes string into array
        obj.exMeshes = obj.exMeshes.split(",");

        // Loop over scene children
        gltf.scene.traverse((child) => {
          // Check if child name matches mesh name
          if (obj.exMeshes.includes(child.name)) {
            // Add mesh reference to scene object
            this.scenes[obj.key][child.name] = child.clone();
          }
        });
      }
    }
  },

  getPresetValue(folder, value) {
    return this.savedPreset['folders'][folder]['controllers'][value]
  },

  createMaterials() {
    // Glass material
    let colorHexValue = new THREE.Color(0x090e85).getHex();
    let roughness = 0.1;
    let metalness = 0.5;
    let emissiveHexValue = new THREE.Color(0x090e85).getHex();
    let emissiveIntensity = 0.127;
    let envMapIntensity = 4;

    if (this.savedPreset !== null) {
      colorHexValue = new THREE.Color(this.getPresetValue('Glass Material', 'Color')).getHex();
      roughness = this.getPresetValue('Glass Material', 'Roughness');
      metalness = this.getPresetValue('Glass Material', 'Metalness');
      emissiveHexValue = new THREE.Color(this.getPresetValue('Glass Material', 'Emissive')).getHex();
      emissiveIntensity = this.getPresetValue('Glass Material', 'Emissive Intensity');
      envMapIntensity = this.getPresetValue('Glass Material', 'Env Map Intensity');
    }

    const glassProperties = {
      color: colorHexValue,
      transparent: true,
      roughness: roughness,
      metalness: metalness,
      ior: 1.5,
      emissive: emissiveHexValue,
      emissiveIntensity: emissiveIntensity,
      transmission: 0,
      envMapIntensity: envMapIntensity,
      depthWrite: true,
      depthTest: true,
      // opacity: 0.8,
      side: THREE.DoubleSide,
    };

    // Glass material controls
    const conf = this.gui.addFolder("Glass Material");
    conf
      .addColor(glassProperties, "color")
      .name("Color")
      .onChange((event) => {
        this.matGlass1.color.setHex(glassProperties.color);
        this.matGlass2.color.setHex(glassProperties.color);
        this.matGlass3.color.setHex(glassProperties.color);
        this.matGlass4.color.setHex(glassProperties.color);
        this.matGlass5.color.setHex(glassProperties.color);
        this.matGlass6.color.setHex(glassProperties.color);
      });
    conf
      .addColor(glassProperties, "emissive")
      .name("Emissive")
      .onChange((event) => {
        this.matGlass1.emissive.setHex(glassProperties.emissive);
        this.matGlass2.emissive.setHex(glassProperties.emissive);
        this.matGlass3.emissive.setHex(glassProperties.emissive);
        this.matGlass4.emissive.setHex(glassProperties.emissive);
        this.matGlass5.emissive.setHex(glassProperties.emissive);
        this.matGlass6.emissive.setHex(glassProperties.emissive);
      });
    conf
      .add(glassProperties, "emissiveIntensity", 0, 1)
      .name("Emissive Intensity");
    conf.add(glassProperties, "roughness", 0, 1).name("Roughness");
    conf.add(glassProperties, "metalness", 0, 1).name("Metalness");
    conf
      .add(glassProperties, "envMapIntensity", 0, 10)
      .name("Env Map Intensity");
    conf.open();

    conf.onChange((event) => {
      if (event.property !== "color" && event.property !== "emissive") {
        this.matGlass1[event.property] = glassProperties[event.property];
        this.matGlass2[event.property] = glassProperties[event.property];
        this.matGlass3[event.property] = glassProperties[event.property];
        this.matGlass4[event.property] = glassProperties[event.property];
        this.matGlass5[event.property] = glassProperties[event.property];
        this.matGlass6[event.property] = glassProperties[event.property];
      }
    });

    this.matGlass1 = new MeshPhysicalMaterialAbs({ ...glassProperties });
    this.matGlass2 = new MeshPhysicalMaterialAbs({ ...glassProperties });
    this.matGlass3 = new MeshPhysicalMaterialAbs({ ...glassProperties });
    this.matGlass4 = new MeshPhysicalMaterialAbs({ ...glassProperties });
    this.matGlass5 = new MeshPhysicalMaterialAbs({ ...glassProperties });
    this.matGlass6 = new MeshPhysicalMaterialAbs({ ...glassProperties });

    // Radial gradient material
    this.matRadial1 = new THREE.ShaderMaterial({
      vertexShader: `
				precision mediump float;
				varying vec2 vUv;
				void main() {
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
					vUv = uv;
				}
			`,
      fragmentShader: `
				uniform vec3 uColor;
				varying vec2 vUv;

				void main() {
						float strength = clamp(1.0 - distance(vUv, vec2(0.5)) * 2.0, 0.0, 1.0);
						vec4 fromColor = vec4(uColor.rgb, 1.0);
						vec4 toColor = vec4(uColor.rgb, 0.0);
						gl_FragColor = mix(toColor, fromColor, strength);
				}
			`,
      transparent: true,
      uniforms: {
        uColor: { value: new THREE.Color("orange").convertLinearToSRGB() },
      },
    });

    this.matRadial2 = this.matRadial1.clone();
  },

  /**
   * Apply preset
   */
  applyPreset(el, material) {
    if (el.dataset.experiencePreset) {
      if (el.dataset.experiencePreset in presets) {
        material.setValues(presets[el.dataset.experiencePreset]);
      }
    }
  },

  /**
   * Hero scene
   */
  setupHeroScene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0.5, 0, 2.5);

    const getDividerAmount = () => {
      let dividerAmount = 0;

      if (window.innerWidth > 1024 && window.innerWidth <= 1440) {
        dividerAmount = 1400;
      } else if (window.innerWidth > 1440 && window.innerWidth <= 1600) {
        dividerAmount = 1380;
      } else if (window.innerWidth > 1600 && window.innerWidth <= 1700) {
        dividerAmount = 1350;
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1900) {
        dividerAmount = 1320;
      } else if (window.innerWidth > 1900 && window.innerWidth <= 2100) {
        dividerAmount = 1280;
      } else if (window.innerWidth > 2100) {
        dividerAmount = 1260;
      } else {
        dividerAmount = 1400;
      }

      return dividerAmount
    }

    // Add and place tubes
    sceneObject.meshHomeHero1.material = this.matGlass1;
    sceneObject.meshHomeHero1.renderOrder = 1;
    sceneObject.meshHomeHero2.material = this.matGlass1;
    sceneObject.meshHomeHero2.renderOrder = 2;
    sceneObject.grpTubes.add(sceneObject.meshHomeHero1);
    sceneObject.grpTubes.add(sceneObject.meshHomeHero2);
    sceneObject.grpTubes.scale.set(5, 5, 5);
    sceneObject.grpTubes.position.x = 1.72 * (window.innerWidth / getDividerAmount());
    sceneObject.grpTubes.position.y = 0.05;
    sceneObject.grpTubes.rotation.y = Math.PI * -0.25;

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass1);

    scene.add(sceneObject.grpTubes);

    window.addEventListener('resize', throttle(function() {
      sceneObject.grpTubes.position.x = 1.72 * (window.innerWidth / getDividerAmount());
    }, 100))

    let scrolledXAmount = sceneObject.grpTubes.scale.x
    let scrolledYAmount = sceneObject.grpTubes.scale.y
    const tubeScaleX = sceneObject.grpTubes.scale.x
    const tubeScaleY = sceneObject.grpTubes.scale.y
    window.addEventListener('scroll', throttle(function() {
      scrolledXAmount = (document.documentElement.scrollTop/500) + tubeScaleX;
      scrolledYAmount = (document.documentElement.scrollTop/500) + tubeScaleY;
    }, 100))

    // Add radial gradient planes
    const plane = new THREE.PlaneGeometry(1, 1);
    const blob1 = new THREE.Mesh(plane, this.matRadial1);
    const blob2 = new THREE.Mesh(plane, this.matRadial2);

    blob1.material.uniforms.uColor.value = this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Hero Orbs', 'Blob 1 Color')).convertLinearToSRGB() : new THREE.Color("#1a2737").convertLinearToSRGB();
    blob2.material.uniforms.uColor.value = this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Hero Orbs', 'Blob 2 Color')).convertLinearToSRGB() : new THREE.Color("#3e2d43").convertLinearToSRGB();

    blob1.position.set(1.25, 0.5, -1);
    blob2.position.set(2.0, -0.5, -1.01);
    blob1.scale.set(3, 3, 3);
    blob2.scale.set(4, 4, 4);
    scene.add(blob1, blob2);

    // Add config
    const conf = this.gui.addFolder("Hero Orbs");
    conf.addColor(blob1.material.uniforms.uColor, "value").name("Blob 1 Color");
    conf.addColor(blob2.material.uniforms.uColor, "value").name("Blob 2 Color");
    conf.open();

    // Setup post processing
    // this.postProcessing("hero");

    /**
     * Animate scene fn for home1
     */
    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      // Float blob1 and blob2
      blob1.position.x = Math.sin(this.clockElapsedTime * 0.5) * 0.5 + 1.25;
      blob1.position.y = Math.sin(this.clockElapsedTime * 0.33) * 0.75 + 0.5;
      blob2.position.x = Math.sin(this.clockElapsedTime * 0.4) * 0.66 + 2.0;
      blob2.position.y = Math.sin(this.clockElapsedTime * 0.2) * 1.25 - 0.5;

      const parallaxX = this.cursor.x
      const parallaxY = - this.cursor.y

      if (parallaxX > 0) {
        gsap.to(sceneObject.grpTubes.position, {
          y: ((parallaxY - sceneObject.grpTubes.position.y) / 10),
          duration: 4,
          ease: "power2.out",
        });

        gsap.to(sceneObject.grpTubes.rotation, {
          y: ((parallaxY - sceneObject.grpTubes.rotation.y) / 10),
          duration: 4,
          ease: "power2.out",
        });

        gsap.to(sceneObject.grpTubes.rotation, {
          x: ((parallaxX - sceneObject.grpTubes.rotation.x) / 10),
          duration: 4,
          ease: "power2.out",
        });
      }

      gsap.to(sceneObject.grpTubes.scale, {
        x: (scrolledXAmount),
        y: (scrolledYAmount),
        duration: 2,
        ease: "power2.out",
      });

      const animate = () => {
        // Animate glass material mix
        gsap.to(this.matGlass1, {
          mix: 1.1,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        // Animate tubes
        gsap.to(sceneObject.grpTubes.rotation, {
          y: 0,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        // Animate camera
        gsap.to(sceneObject.camera.position, {
          x: 0,
          y: 0,
          z: 3,
          duration: 3,
          delay: 0.5,
          ease: "power2.out",
        });

        sceneObject.triggered = true;
      }

      if (!sceneObject.triggered) {
        if (Cookies.get('intro-shown') !== "true") {
          setTimeout(function() {
            animate()
          }, 3600)
        } else {
          animate()
        }
      }

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Hero projects scene
   */
  setupHeroProjectsScene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 3);

    // Add and place tubes
    sceneObject.meshFooter1.material = this.matGlass2;
    sceneObject.meshFooter1.renderOrder = 1;
    sceneObject.meshFooter2.material = this.matGlass2;
    sceneObject.meshFooter2.renderOrder = 2;
    sceneObject.grpTubes.add(sceneObject.meshFooter1);
    sceneObject.grpTubes.add(sceneObject.meshFooter2);
    sceneObject.grpTubes.scale.set(5, 5, 5);

    if (window.innerWidth >= 1024 && window.innerWidth < 1600) {
      sceneObject.grpTubes.position.x = 1.5;
      sceneObject.grpTubes.position.y = 0.55;
    } else if (window.innerWidth > 1600 && window.innerWidth < 2000) {
      sceneObject.grpTubes.position.x = 1.9;
      sceneObject.grpTubes.position.y = 0.55;
    } else {
      sceneObject.grpTubes.position.x = 3.5;
      sceneObject.grpTubes.position.y = 1;
    }

    window.addEventListener('resize', throttle(function() {
      if (window.innerWidth >= 1024 && window.innerWidth < 1600) {
        sceneObject.grpTubes.position.x = 1.5;
        sceneObject.grpTubes.position.y = 0.55;
      } else if (window.innerWidth > 1600 && window.innerWidth < 2000) {
        sceneObject.grpTubes.position.x = 1.9;
        sceneObject.grpTubes.position.y = 0.55;
      } else {
        sceneObject.grpTubes.position.x = 3.5;
        sceneObject.grpTubes.position.y = 1;
      }

    }, 100))

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass2);

    scene.add(sceneObject.grpTubes);

    // Add radial gradient planes
    const plane = new THREE.PlaneGeometry(1, 1);
    const blob1 = new THREE.Mesh(plane, this.matRadial1);
    const blob2 = new THREE.Mesh(plane, this.matRadial2);

    blob1.material.uniforms.uColor.value = this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Hero Orbs', 'Blob 1 Color')).convertLinearToSRGB() : new THREE.Color("#1a2737").convertLinearToSRGB();
    blob2.material.uniforms.uColor.value = this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Hero Orbs', 'Blob 2 Color')).convertLinearToSRGB() : new THREE.Color("#3e2d43").convertLinearToSRGB();

    blob1.position.set(1.25, -0.5, -1);
    blob2.position.set(2.0, -0.75, -1.01);
    blob1.scale.set(3, 3, 3);
    blob2.scale.set(4, 4, 4);
    scene.add(blob1, blob2);

    // Add config
    const conf = this.gui.addFolder("Hero Orbs");
    conf.addColor(blob1.material.uniforms.uColor, "value").name("Blob 1 Color");
    conf.addColor(blob2.material.uniforms.uColor, "value").name("Blob 2 Color");
    conf.addColor(blob1.position, "y").name("Blob 1 Y");
    conf.addColor(blob2.position, "y").name("Blob 2 Y");
    conf.open();

    // Setup post processing
    // this.postProcessing("hero");

    /**
     * Animate scene fn for home1
     */
    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      // Float blob1 and blob2
      blob1.position.x = Math.sin(this.clockElapsedTime * 0.5) * 0.5 + 1.25;
      blob1.position.y = Math.sin(this.clockElapsedTime * 0.33) * 0.75 + 0.5;
      blob2.position.x = Math.sin(this.clockElapsedTime * 0.4) * 0.66 + 2.0;
      blob2.position.y = Math.sin(this.clockElapsedTime * 0.2) * 1.25 - 0.5;

      if (!sceneObject.triggered) {
        // Animate glass material mix
        gsap.to(this.matGlass2, {
          mix: 1.1,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        // Animate tubes
        gsap.to(sceneObject.grpTubes.rotation, {
          y: 0,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        // Animate camera
        gsap.to(sceneObject.camera.position, {
          x: 0,
          y: 0,
          z: 3,
          duration: 3,
          delay: 0.5,
          ease: "power2.out",
        });

        sceneObject.triggered = true;
      }

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Footer scene
   */
  setupFooterScene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Add and place tubes
    sceneObject.meshFooter1.material = this.matGlass2;
    sceneObject.meshFooter1.renderOrder = 1;
    sceneObject.meshFooter2.material = this.matGlass2;
    sceneObject.meshFooter2.renderOrder = 2;
    sceneObject.grpTubes.add(sceneObject.meshFooter1);
    sceneObject.grpTubes.add(sceneObject.meshFooter2);
    sceneObject.grpTubes.position.y = 0.15;
    sceneObject.grpTubes.position.z = 0.582;
		sceneObject.grpTubes.position.x = 0.75;
    sceneObject.grpTubes.scale.set(2, 2, 2);


    window.addEventListener('resize', throttle(function() {

    }, 100))

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass2);

    scene.add(sceneObject.grpTubes);

    // Adjust rotation of tubes group
    sceneObject.grpTubes.rotation.y = -0.06;

    // Setup post processing
    // this.postProcessing("footer");

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      if (!sceneObject.triggered) {
        // Animate glass material mix
        gsap.to(this.matGlass2, {
          mix: 1.1,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        sceneObject.triggered = true;
      }

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Approach 1 scene
   */
  setupApproach1Scene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass3);

    // Add and place tubes
    sceneObject.meshApproach1a.material = this.matGlass3;
    sceneObject.meshApproach1a.renderOrder = 1;
    sceneObject.meshApproach1b.material = this.matGlass3;
    sceneObject.meshApproach1b.renderOrder = 2;
    sceneObject.grpTubes.add(sceneObject.meshApproach1a, sceneObject.meshApproach1b);
    sceneObject.grpTubes.position.x = 1.0;
    sceneObject.grpTubes.position.y = 0.2;
    sceneObject.grpTubes.scale.set(2, 2, 2);
    scene.add(sceneObject.grpTubes);

    if (window.innerWidth > 1700) {
      sceneObject.grpTubes.scale.set(3.25, 2.25, 2.25);
    }

    new ApproachExperienceAnimation(sceneObject, this.matGlass3);
    new ApproachHeadingAnimation(sceneObject);

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Approach 2 scene
   */
  setupApproach2Scene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass4);

    // Add and place tubes
    sceneObject.meshApproach2.material = this.matGlass4;
    sceneObject.meshApproach2.renderOrder = 1;
    sceneObject.grpTubes.add(sceneObject.meshApproach2);
    sceneObject.grpTubes.position.x = -.5;
    sceneObject.grpTubes.position.y = -.1;
    sceneObject.grpTubes.scale.set(2, 2, 2);
    scene.add(sceneObject.grpTubes);

    // Stretch tubes to ensure no cut off on extra large screens
    if (window.innerWidth > 1700) {
      sceneObject.grpTubes.scale.set(3.25, 2.25, 2.25);
    }

		new ApproachExperienceAnimation(sceneObject, this.matGlass4);
		new ApproachHeadingAnimation(sceneObject);

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Approach 3 scene
   */
  setupApproach3Scene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass5);

    // Add and place tubes
    sceneObject.meshApproach3a.material = this.matGlass5;
    sceneObject.meshApproach3a.renderOrder = 2;
		sceneObject.meshApproach3b.material = this.matGlass5;
		sceneObject.meshApproach3b.renderOrder = 1;
    sceneObject.grpTubes.add(sceneObject.meshApproach3a, sceneObject.meshApproach3b);
    sceneObject.grpTubes.position.x = 0;
    sceneObject.grpTubes.position.y = 0;
    sceneObject.grpTubes.scale.set(2, 2, 2);
    scene.add(sceneObject.grpTubes);

    if (window.innerWidth > 1700) {
      sceneObject.grpTubes.scale.set(3.25, 2.25, 2.25);
    }

    new ApproachExperienceAnimation(sceneObject, this.matGlass5);
    new ApproachHeadingAnimation(sceneObject);

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Approach 4 scene
   */
  setupApproach4Scene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 3.1);

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass6);

    // Add and place tubes
    sceneObject.meshApproach4a.material = this.matGlass6;
    sceneObject.meshApproach4a.renderOrder = 2;
    sceneObject.meshApproach4b.material = this.matGlass6;
    sceneObject.meshApproach4b.renderOrder = 1;
    sceneObject.grpTubes.add(sceneObject.meshApproach4a, sceneObject.meshApproach4b);
    sceneObject.grpTubes.position.x = 0;
    sceneObject.grpTubes.position.y = 0;
    sceneObject.grpTubes.scale.set(2, 2, 2);
    scene.add(sceneObject.grpTubes);

    if (window.innerWidth > 1700) {
      sceneObject.grpTubes.scale.set(3.25, 2.25, 2.25);
    }

    new ApproachExperienceAnimation(sceneObject, this.matGlass6);
    new ApproachHeadingAnimation(sceneObject);

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * People scene
   */
  setupPeopleScene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass4);

    // Add and place tubes
    sceneObject.meshApproach2.material = this.matGlass4;
    sceneObject.meshApproach2.renderOrder = 1;
    sceneObject.grpTubes.add(sceneObject.meshApproach2);
    sceneObject.grpTubes.rotation.x = 0.08;
    sceneObject.grpTubes.rotation.y = 0.08;
    sceneObject.grpTubes.rotation.z = 0.21;
    sceneObject.grpTubes.position.x = -.5;
    sceneObject.grpTubes.position.y = -.1;
    sceneObject.grpTubes.scale.set(2, 2, 2);
    scene.add(sceneObject.grpTubes);

    // Add config
    const conf = this.gui.addFolder("People");
    conf.add(sceneObject.grpTubes.rotation, "x", -5, 5).name('Rotation X');
    conf.add(sceneObject.grpTubes.rotation, "y", -5, 5).name("Rotation Y");
    conf.add(sceneObject.grpTubes.rotation, "z", -5, 5).name("Rotation Z");
    conf.open();

    // Stretch tubes to ensure no cut off on extra large screens
    if (window.innerWidth > 1700) {
      sceneObject.grpTubes.scale.set(3.25, 2.25, 2.25);
    }

    new PeopleExperienceAnimation(sceneObject, this.matGlass4);

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Careers scene
   */
  setupCareersScene(sceneObject) {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();

    sceneObject.scene = scene;
    sceneObject.camera = camera;
    camera.position.set(0, 0, 2.5);

    // Add and place tubes
    sceneObject.meshFooter1.material = this.matGlass2;
    sceneObject.meshFooter1.renderOrder = 1;
    sceneObject.meshFooter2.material = this.matGlass2;
    sceneObject.meshFooter2.renderOrder = 2;
    sceneObject.grpTubes.add(sceneObject.meshFooter1);
    sceneObject.grpTubes.add(sceneObject.meshFooter2);
    sceneObject.grpTubes.position.z = 0.582;
    sceneObject.grpTubes.rotation.x = 0.264;
    sceneObject.grpTubes.rotation.y = -0.032;

    if (window.innerWidth >= 1280) {
      sceneObject.grpTubes.position.y = 0.264;
      sceneObject.grpTubes.position.x = -0.088;
      sceneObject.grpTubes.scale.set(1.248, 1.248, 1.248);
    } else {
      sceneObject.grpTubes.position.y = 0.24;
      sceneObject.grpTubes.position.x = -0.1;
      sceneObject.grpTubes.scale.set(1, 1, 1);
    }

    window.addEventListener('resize', throttle(function() {
      if (window.innerWidth >= 1280) {
        sceneObject.grpTubes.position.y = 0.264;
        sceneObject.grpTubes.position.x = -0.088;
        sceneObject.grpTubes.scale.set(1.248, 1.248, 1.248);
      } else {
        sceneObject.grpTubes.position.y = 0.24;
        sceneObject.grpTubes.position.x = -0.1;
        sceneObject.grpTubes.scale.set(1, 1, 1);
      }
    }, 100))

    // Apply preset
    this.applyPreset(sceneObject.el, this.matGlass2);

    scene.add(sceneObject.grpTubes);



    // Add config
    const conf = this.gui.addFolder("Careers");
    conf.add(sceneObject.grpTubes.position, "x", -4, 4).name("Pos X");
    conf.add(sceneObject.grpTubes.position, "y", -4, 4).name("Pos Y");
    conf.add(sceneObject.grpTubes.rotation, "x", -4, 4).name("Rotate X");
    conf.add(sceneObject.grpTubes.rotation, "y", -4, 4).name("Rotate Y");
    conf.add(sceneObject.grpTubes.scale, "x", -4, 4).name("Scale X");
    conf.add(sceneObject.grpTubes.scale, "y", -4, 4).name("Scale Y");
    conf.open();

    // Setup post processing
    // this.postProcessing("footer");

    sceneObject.animate = (rect) => {
      camera.aspect = rect.width / rect.height;
      camera.updateProjectionMatrix();

      if (!sceneObject.triggered) {
        // Animate glass material mix
        gsap.to(this.matGlass2, {
          mix: 1.1,
          duration: 4,
          delay: 0.5,
          ease: "power2.out",
        });

        sceneObject.triggered = true;
      }

      this.renderer.render(scene, camera);
      // sceneObject.composer.render();
    };
  },

  /**
   * Render custom scene into buffer using cube camera.
   * Use this to create a dynamic environment maps
   */
  setupEnvironmentScene() {
    // Init scene object
    this.scenes.environment = {
      animate: () => {},
      scene: null,
      scissor: false,
    };

    const bufferRenderTarget = new THREE.WebGLCubeRenderTarget(512);
    bufferRenderTarget.texture.type = THREE.HalfFloatType;

    const scene = new THREE.Scene();
    const camera = new THREE.CubeCamera(0.1, 1000, bufferRenderTarget);
    camera.position.set(0, 0, 0);

    const grpEnvLights = new THREE.Group();
    const planeWideShort = new THREE.PlaneGeometry(10, 0.2);
    const planeTallShort = new THREE.PlaneGeometry(0.2, 10);
    const planeRegular = new THREE.PlaneGeometry(3, 4);

    const planeMaterial1 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 1 Color')).getHex() : "red",
      side: THREE.DoubleSide,
    });
    const planeMaterial2 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 2 Color')).getHex() : "cyan",
      side: THREE.DoubleSide,
    });
    const planeMaterial3 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 3 Color')).getHex() : "orange",
      side: THREE.DoubleSide,
    });
    const planeMaterial4 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 4 Color')).getHex() : "purple",
      side: THREE.DoubleSide,
    });
    const planeMaterial5 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 5 Color')).getHex() : "pink",
      side: THREE.DoubleSide,
    });
    const planeMaterial6 = new THREE.MeshBasicMaterial({
      color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Environment Planes', 'Plane 6 Color')).getHex() : "yellow",
      side: THREE.DoubleSide,
    });

    const plane1 = new THREE.Mesh(planeWideShort, planeMaterial1);
    const plane2 = new THREE.Mesh(planeRegular, planeMaterial2);
    const plane3 = new THREE.Mesh(planeWideShort, planeMaterial3);
    const plane4 = new THREE.Mesh(planeTallShort, planeMaterial4);
    const plane5 = new THREE.Mesh(planeTallShort, planeMaterial5);
    const plane6 = new THREE.Mesh(planeTallShort, planeMaterial6);
    plane1.position.set(0, 0, -1);
    plane2.position.set(0, 0, 1);
    plane3.position.set(0, 1, 0);
    plane4.position.set(0, -1, 0);
    plane5.position.set(1, 0, 0);
    plane6.position.set(-1, 0, 0);
    plane3.rotation.x = Math.PI * -0.5;
    plane4.rotation.x = Math.PI * -0.5;
    plane5.rotation.y = Math.PI * -0.5;
    plane6.rotation.y = Math.PI * -0.5;
    grpEnvLights.add(plane1);
    grpEnvLights.add(plane2);
    grpEnvLights.add(plane3);
    grpEnvLights.add(plane4);
    grpEnvLights.add(plane5);
    grpEnvLights.add(plane6);
    scene.add(grpEnvLights);

    // Add config for plane colours
    const conf = this.gui.addFolder("Environment Planes");
    conf.addColor(planeMaterial1, "color").name("Plane 1 Color");
    conf.addColor(planeMaterial2, "color").name("Plane 2 Color");
    conf.addColor(planeMaterial3, "color").name("Plane 3 Color");
    conf.addColor(planeMaterial4, "color").name("Plane 4 Color");
    conf.addColor(planeMaterial5, "color").name("Plane 5 Color");
    conf.addColor(planeMaterial6, "color").name("Plane 6 Color");
    conf.open();

    // Animate callback
    this.scenes.environment.animate = () => {
      grpEnvLights.rotation.y += this.clockDelta * 0.5;
      grpEnvLights.rotation.x += this.clockDelta * 0.25;

      camera.update(this.renderer, scene);

      // Loop over scenes and set env map where useGlobalEnvMap is true
      for (const key in this.scenes) {
        const { scene } = this.scenes[key];
        if (this.scenes[key].useGlobalEnvMap) {
          scene.environment = camera.renderTarget.texture;
        }
      }
    };
  },

  /**
   * Add ambient and directional lights
   */
  addGlobalLights() {
    const lightConfig = {
      light1: {
        color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Lights', 'Light 1 Color')).getHex() : new THREE.Color(0xff6a3a).getHex(),
        intensity: this.savedPreset !== null ? this.getPresetValue('Lights', 'Light 1 Intensity') : 20,
        lightIndex: 0,
        position: [-10, -5],
      },
      light2: {
        color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Lights', 'Light 2 Color')).getHex() : new THREE.Color(0xffa2b7).getHex(),
        intensity: this.savedPreset !== null ? this.getPresetValue('Lights', 'Light 2 Intensity') : 30,
        lightIndex: 1,
        position: [-5, -0.5, -8],
      },
      light3: {
        color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Lights', 'Light 3 Color')).getHex() : new THREE.Color(0xff0000).getHex(),
        intensity: this.savedPreset !== null ? this.getPresetValue('Lights', 'Light 3 Intensity') : 40,
        lightIndex: 2,
        position: [5, -1, 7],
      },
      light4: {
        color: this.savedPreset !== null ? new THREE.Color(this.getPresetValue('Lights', 'Light 4 Color')).getHex() : new THREE.Color(0x00ffff).getHex(),
        intensity: this.savedPreset !== null ? this.getPresetValue('Lights', 'Light 4 Intensity') : 50,
        lightIndex: 3,
        position: [9, 1, 9],
      },
    };

    const light1a = new THREE.DirectionalLight(
      lightConfig.light1.color,
      lightConfig.light1.intensity
    );
    light1a.position.set(...lightConfig.light1.position);

    const light2a = new THREE.DirectionalLight(
      lightConfig.light2.color,
      lightConfig.light2.intensity
    );
    light2a.position.set(...lightConfig.light2.position);

    const light3a = new THREE.DirectionalLight(
      lightConfig.light3.color,
      lightConfig.light3.intensity
    );
    light3a.position.set(...lightConfig.light3.position);

    const light4a = new THREE.DirectionalLight(
      lightConfig.light4.color,
      lightConfig.light4.intensity
    );
    light4a.position.set(...lightConfig.light4.position);

    const lightGroup = new THREE.Group();
    lightGroup.add(light1a, light2a, light3a, light4a);

    // Add cloned light group to each scene
    const lightGroups = [lightGroup];
    for (const key in this.scenes) {
      const { scene } = this.scenes[key];
      if (this.scenes[key].useGlobalLights) {
        const lightGroupClone = lightGroup.clone();
        lightGroups.push(lightGroupClone);
        scene.add(lightGroupClone);
      }
    }

    // Add controls for all lights' intensity and colour
    const conf = this.gui.addFolder("Lights");
    conf.addColor(lightConfig.light1, "color").name("Light 1 Color");
    conf.add(lightConfig.light1, "intensity", 0, 50).name("Light 1 Intensity");
    conf.addColor(lightConfig.light2, "color").name("Light 2 Color");
    conf.add(lightConfig.light2, "intensity", 0, 50).name("Light 2 Intensity");
    conf.addColor(lightConfig.light3, "color").name("Light 3 Color");
    conf.add(lightConfig.light3, "intensity", 0, 50).name("Light 3 Intensity");
    conf.addColor(lightConfig.light4, "color").name("Light 4 Color");
    conf.add(lightConfig.light4, "intensity", 0, 50).name("Light 4 Intensity");
    conf.open();

    // Update light colours and intensities taking into account light number
    conf.onChange((event) => {
      if (event.property === "color") {
        // Set all light colours at index event.controller.object.lightIndex in lightGroups array
        lightGroups.forEach((lightGroup) => {
          lightGroup.children[event.controller.object.lightIndex].color.setHex(
            event.controller.object.color
          );
        });
      } else {
        // Generic update using event.property
        lightGroups.forEach((lightGroup) => {
          lightGroup.children[event.controller.object.lightIndex][
            event.property
          ] = event.controller.object[event.property];
        });
      }
    });
  },

  /**
   * Add post processing effects
   * @param {String} scene - Name of scene to add post processing to
   */
  postProcessing(scene) {
    const renderTarget = new THREE.WebGLRenderTarget(800, 600, {
      samples: 16,
    });

    const renderScene = new RenderPass(
      this.scenes[scene].scene,
      this.scenes[scene].camera
    );
    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5, // strength
      0.4, // radius
      0.85 // threshold
    );

    this.scenes[scene].composer = new EffectComposer(
      this.renderer,
      renderTarget
    );
    this.scenes[scene].composer.setSize(
      this.containerSize.width,
      this.containerSize.height
    );
    this.scenes[scene].composer.setPixelRatio(
      Math.min(window.devicePixelRatio, 16)
    );
    this.scenes[scene].composer.addPass(renderScene);
    this.scenes[scene].composer.addPass(bloomPass);

    // Antialias pass
    if (
      this.renderer.getPixelRatio() === 1 &&
      !this.renderer.capabilities.isWebGL2
    ) {
      const smaaPass = new SMAAPass();
      this.scenes[scene].composer.addPass(smaaPass);
    }

    const conf = this.gui.addFolder("Bloom");
    conf.add(bloomPass, "threshold", 0, 1).name("Threshold");
    conf.add(bloomPass, "strength", 0, 10).name("Strength");
    conf.add(bloomPass, "radius", 0, 1).name("Radius");
    conf.open();
  },

  /**
   * Resize canvas to fit window
   */
  resize() {
    this.containerSize.width = this.container.clientWidth;
    this.containerSize.height = this.container.clientHeight;
    this.renderer.setSize(this.containerSize.width, this.containerSize.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  },

  /**
   * Render loop
   */
  render() {
    // calulcate delta time
    const now = Date.now();
    this.clockDelta = (now - this.clockLastTime) / 1000; // in seconds
    this.clockElapsedTime += this.clockDelta;
    this.clockLastTime = now;

    // Begin stats recording
    // this.stats.begin();

    this.renderer.setScissorTest(false);
    this.renderer.clear(true, true);
    this.renderer.setScissorTest(true);

    // Keep the canvas element in sync with scroll
    if (this.containerSync === "transform") {
      const transform = `translateY(${window.scrollY}px) translateZ(0)`;
      this.renderer.domElement.style.willChange = "transform";
      this.renderer.domElement.style.transform = transform;
    }

    for (const key in this.scenes) {
      const { el, animate, scissor } = this.scenes[key];

      if (scissor) {
        // get the viewport relative position of this element
        const rect = el.getBoundingClientRect();
        const { left, right, top, bottom, width, height } = rect;

        const isOffscreen =
          bottom < 0 ||
          top > this.renderer.domElement.clientHeight ||
          right < 0 ||
          left > this.renderer.domElement.clientWidth;

        if (!isOffscreen) {
          const positiveYUpBottom =
            this.renderer.domElement.clientHeight - bottom;
          this.renderer.setScissor(left, positiveYUpBottom, width, height);
          this.renderer.setViewport(left, positiveYUpBottom, width, height);

          animate(rect);
        }
      } else {
        animate();
      }
    }

    // End stats recording
    // this.stats.end();

    window.requestAnimationFrame(this.render.bind(this));
  },

  /**
   * Destroy scene
   */
  destroy() {
    this.renderer.domElement.remove();
    window.removeEventListener("resize", this.resize.bind(this));
  },
};

if (document.readyState === 'interactive' || document.readyState === 'complete') {
  const experiences = document.querySelectorAll("[data-experience]");

  if (experiences.length) {
    experience.init(experiences);
  }
} else {
  window.addEventListener("DOMContentLoaded", function () {
    const experiences = document.querySelectorAll("[data-experience]");

    if (experiences.length) {
      experience.init(experiences);
    }
  });
}
